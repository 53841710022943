import React from "react"
import { graphql } from "gatsby"
import { Container, Text, Box, Heading, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getBlogPath, getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHeroHome from "./pageHeroHome"
import AccordionHome from "../components/blocks/accordionHome"
import ContactForm from "../components/blocks/contactForm"
import GridParagraphAndIcon from "../components/blocks/GridParagraphAndIcon"
import ImageAndText from "../components/blocks/imageAndText"
import ArticleThumb from "../components/articleThumb"
import { InboundLink } from "../components/link"
import BannerHome from "../components/blocks/bannerHome"
const Home = ({ data: { page, site, articles } }) => {
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={page.locale} />
        <meta
          name="facebook-domain-verification"
          content="t4d4masd2zv9txogsy35otby42m59z"
        />
      </HelmetDatoCms>
      <Container variant="header">
        <PageHeroHome page={page} image={page.heroImage} />
      </Container>

      {page.content.map(
        block =>
          block.model &&
          block.model.apiKey && (
            <Box as="section" key={block.id}>
              {block.model.apiKey === "accordion" && (
                <AccordionHome page={block} items={block.content} />
              )}
              {block.model.apiKey === "contact_form" && (
                <ContactForm
                  title={block.title}
                  subtitle={block.subtitle}
                  description={block.description}
                />
              )}
              {block.model.apiKey === "grid_paragraph_and_icon" && (
                <GridParagraphAndIcon
                  label={block.label}
                  title={block.title}
                  subtitle={block.subtitle}
                  items={block.content}
                />
              )}
              {block.model.apiKey === "image_and_text" && (
                <ImageAndText
                  link={block.link}
                  title={block.title}
                  images={block.image}
                  label={block.label}
                  body={block.description}
                  subtitle={block.subtitle}
                  paddingtop={block.paddingtop}
                />
              )}
            </Box>
          )
      )}

      <Container sx={{ mb: [3, 7] }}>
        <Box
          sx={{
            width: "100%",
            zIndex: -1,
            position: "relative",
            p: {
              left: "50%",
              transform: "translateX(-50%)",
              position: "absolute",
              fontSize: [9, 9, 10, 14],
              zIndex: -1,
              color: "#eeeeee",
            },
          }}
        >
          <Text as="p" variant="h2" sx={{ color: "secondary", mb: 6 }}>
            Blog
          </Text>
        </Box>
        <Heading
          as="h2"
          variant="h2"
          sx={{ color: "secondary", mb: 6, textAlign: "center" }}
        >
          Il blog di Caldofacile
        </Heading>
        <Box>
          {articles.nodes.map(article => (
            <ArticleThumb key={article.id} article={article} />
          ))}
        </Box>
        <Flex sx={{ justifyContent: "center", mt: [5, 6] }}>
          <InboundLink
            to="/blog/"
            sx={{
              paddingY: 3,
              paddingX: 5,
              cursor: "pointer",
              color: "light",
              bg: "primary",
              "&:hover": {
                bg: "dark",
              },
            }}
          >
            Vedi tutti gli articoli
          </InboundLink>
        </Flex>
      </Container>
      <Box sx={{ mb: "30px" }} as="section">
        {page.content.map(block => {
          if (block.model.apiKey === "banner") {
            return <BannerHome block={block} />
          }
        })}
      </Box>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      label
      mainTitle
      subtitle
      paragraph
      heroImage {
        gatsbyImageData(width: 1920, placeholder: BLURRED, forceBlurhash: false)
      }
      viewMore {
        ... on DatoCmsInternalLink {
          id
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsBanner {
          model {
            apiKey
          }
          background {
            hex
          }
          id
          body
          bannerImage: image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          pageLink: link {
            ... on DatoCmsInternalLink {
              id: originalId
              anchor
              locale
              model {
                apiKey
              }

              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
            ... on DatoCmsExternalLink {
              id: originalId
              anchor
              url
              model {
                apiKey
              }
            }
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          label
          content {
            id
            ... on DatoCmsProdotto {
              id
              description
              title
              subtitle
              image {
                gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
              }
              viewMore {
                ... on DatoCmsInternalLink {
                  id: originalId
                  anchor
                  locale
                  model {
                    apiKey
                  }
                  link {
                    ... on DatoCmsPage {
                      ...PageDetails
                      ...PageTreeParent
                      ...AllSlugLocales
                    }
                  }
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          title
          subtitle
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsGridParagraphAndIcon {
          id
          label
          title
          subtitle
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          centerimage
          paddingtop
          subtitle
          title
          description
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
          }
          model {
            apiKey
          }
        }
      }
      model {
        apiKey
      }
    }
    site: datoCmsSite {
      locales
    }
    articles: allDatoCmsArticle(
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        locale
        slug
        heroImage {
          gatsbyImageData(
            width: 720
            placeholder: NONE
            forceBlurhash: false
            imgixParams: { duotone: "993416,000000", duotoneAlpha: 10 }
          )
        }
        meta {
          firstPublishedAt(formatString: "dddd D MMMM YYYY", locale: "it")
        }
        body {
          value
        }
        _allSlugLocales {
          value
          locale
        }
        seo {
          description
        }
      }
    }
  }
`
