import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading, Text } from "theme-ui"
import linkSwitch from "../utils/linkSwitch"
import * as Scroll from "react-scroll"
import { InboundLink } from "../components/link"

const PageHeroHome = ({ page, image }) => {
  let LinkScroll = Scroll.Link
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "primary",
        mb: 4,
        minHeight: ["100%"],
        height: ["100%"],
      }}
    >
      {image && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: ["100%"],
            width: "100%",
            ".gatsby-image-wrapper": {
              height: ["100%"],
              width: "100%",
            },
            overflow: "hidden",
          }}
        >
          <GatsbyImage image={image.gatsbyImageData} alt="" />
        </Box>
      )}
      <Container
        sx={{
          paddingTop: [5, 9],
          px: [3, 3, 3, 6],
          position: "relative",
          height: "100%",
        }}
      >
        <Heading as="h1" variant="h1" color="light">
          <Box
            as="h3"
            color="light"
            sx={{
              position: "relative",
              fontWeight: "400",
              letterSpacing: "-.5px",
              fontSize: [4, 6],
            }}
          >
            {page.label}
          </Box>
          <Box
            as="h3"
            color="light"
            sx={{
              position: "relative",
              fontFamily: "Roboto",
              my: [3],
              textTransform: "uppercase",
              fontSize: ["40px", 7, 8, 10],
              transform: [
                "translateX(0%)",
                "translateX(0%)",
                "translateX(0%)",
                "translateX(-50%)",
              ],
              left: ["0%", "0%", "0%", "45%"],
            }}
          >
            {page.mainTitle}
          </Box>
          <Box
            as="h3"
            color="light"
            sx={{
              position: "relative",
              fontWeight: "700",
              letterSpacing: "-.5px",
              fontSize: [4, 6],
            }}
          >
            {page.subtitle}
          </Box>
        </Heading>
        <Box
          color="textLight"
          sx={{
            p: {
              fontWeight: "400",
              letterSpacing: "2px",
              fontSize: "14px",
              pt: [2, 2, 0, 0],
              lineHeight: ["1.5", 2],
            },
          }}
          dangerouslySetInnerHTML={{ __html: page.paragraph }}
        />
        <Box pt={4}>
          <InboundLink
            href={linkSwitch(page.viewMore, page.locale).href}
            sx={{ fontWeight: "bold" }}
            variant="buttons.full.primary"
          >
            {page.viewMore.anchor} +
          </InboundLink>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: ["flex-end"],
          }}
        >
          <Box
            sx={{
              position: "relative",
              bottom: 0,
              width: ["100%", "350px"],
              my: [5],
              right: [0],
              mb: [-3, -4],
              borderTop: "8px solid",
              borderColor: "primary",
              backgroundColor: "light",
              pt: [4, 4, 4, 4],
              pb: [4, 4, 4, 4],
              pl: [4, 4, 4, 4],
              pr: [5, 5, 8],
            }}
          >
            <LinkScroll
              variant="buttons.full.primary"
              to="contactForm"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "riciedi_info",
                    formType: "Contact",
                    pageUrl: window.location.href,
                  })
                }
              }}
            >
              <Heading
                as="h6"
                variant="h6"
                sx={{ fontWeight: "bold", letterSpacing: "3.57px", m: [0] }}
              >
                <>
                  Le soluzioni
                  <br />
                  Caldofacile
                  <br />
                  <Text
                    as="p"
                    variant="p"
                    sx={{
                      fontSize: [2],
                      pt: [3, 3, 3, 3],
                      position: "relative",
                      paddingLeft: "52px",
                      "&:before": {
                        content: "' '",
                        position: "absolute",
                        top: "65%",
                        left: 0,
                        transform: "translateY(-50%)",
                        backgroundColor: "primary",
                        width: "42px",
                        height: "2px",
                      },
                    }}
                  >
                    Richiedi info +
                  </Text>
                </>
              </Heading>
            </LinkScroll>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default PageHeroHome
