import React, { useState } from "react"
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  Flex,
  Link,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import { ArrowUp, ArrowDown } from "react-feather"
import linkSwitch from "../../utils/linkSwitch"
import { i18nContext } from "../../context/i18nContext"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import * as Scroll from "react-scroll"
import { InboundLink , OutboundLink } from "../link"

const Accordion = ({ page, items }) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const dark = getColor(themeUiTheme, "dark")
  const light = getColor(themeUiTheme, "light")
  const scroller = Scroll.scroller;

  const handleSelectScrollActivate = index => {
    // scroller.scrollTo(index, {
    //   duration: 300,
    //   delay: 0,
    //   smooth: true,
    //   offset: -15, // Scrolls to element + 50 pixels down the page
    // })
    setSelectedItem(index)
  }

  return (
    <Box sx={{ py: [3,3,0,0] }}>
      <Container>
        <Box>
          <Text variant="caption">{page.label}</Text>
          <Heading
            as="div"
            variant="sectionTitle"
            sx={{
              fontSize: [6],
              fontWeight: "body",
              letterSpacing: "-.5px",
              lineHeight: "1.12",
              em: {
                fontStyle: "normal",
                color: "primary",
              },
            }}
            dangerouslySetInnerHTML={{ __html: page.title }}
          />
        </Box>
        <Grid
          columns={["1fr", "1fr", "1fr", "1fr 1fr"]}
          gap={[4]}
          sx={{ alignItems: "center", alignContent: "center", pt: [4,7] }}
        >
          <AccordionWrapper
            preExpanded={[0]}
            onChange={e => handleSelectScrollActivate(e[0])}
            allowMultipleExpanded={false}
            allowZeroExpanded={false}
          >
            {items.map((item, index) => (
              <Box
                sx={{
                  py: [1],
                }}
              >
                <AccordionItem key={item.id} uuid={index} name={index}>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <Box
                              sx={{
                                px: [4],
                                pb: [expanded ? 4 : 6],
                                pt: [4],
                                cursor: "pointer",
                                backgroundColor: expanded ? "dark" : "gray",
                                "&:hover": {
                                  backgroundColor: expanded ? "dark" : "gray",
                                },
                              }}
                            >
                              <Flex
                                sx={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <Box>
                                  <Heading
                                    sx={{
                                      width: ["80%","100%"],
                                      color: expanded ? "light" : "dark",
                                      fontWeight: "bold",
                                      m: 0,
                                    }}
                                    variant="h6"
                                  >
                                    {item.title}
                                  </Heading>
                                </Box>

                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: [2],
                                    top: 0,
                                    p: [2],
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "48px",
                                    height: "48px",
                                    border: "2px solid",
                                    borderRadius: "1px",
                                    borderColor: expanded ? "light" : "dark",
                                  }}
                                >
                                  {expanded ? (
                                    <ArrowUp size="24" color={light} />
                                  ) : (
                                    <ArrowDown size="24" color={dark} />
                                  )}
                                </Box>
                              </Flex>
                            </Box>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Box
                            sx={{ backgroundColor: expanded ? "dark" : "gray" }}
                          >
                            <Box sx={{ px: [4], width: ["100%","100%","100%","80%"] }}>
                              <Text
                                as="div"
                                variant="p"
                                sx={{
                                  color: expanded ? "light" : "dark",
                                  p: { m: 0, letterSpacing: "2px" },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <Box
                                sx={{
                                  display: ["flex", "flex", "flex", "none"],
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "4px auto",
                                  my: 4,
                                  width: ["100%","80%","60%"],
                                }}
                              >
                                <GatsbyImage
                                  image={
                                    items[selectedItem].image.gatsbyImageData
                                  }
                                  alt=""
                                />
                              </Box>
                              <i18nContext.Consumer>
                                {t => (
                                  <Box sx={{ py: [4] }}>
                                    <InboundLink
                                      variant="buttons.primary"
                                      sx={{
                                        color: "primary",
                                        fontWeight: "400",
                                        my: [2],
                                      }}
                                      to={linkSwitch(item.viewMore).href}
                                    >
                                      {t.goToProduct} +
                                    </InboundLink>
                                  </Box>
                                )}
                              </i18nContext.Consumer>
                            </Box>
                          </Box>
                        </AccordionItemPanel>
                      </>
                    )}
                  </AccordionItemState>
                </AccordionItem>
              </Box>
            ))}
          </AccordionWrapper>
          <Box
            sx={{
              mb: 2,
              display: ["none", "none", "none", "flex"],
              justifyContent: "center",
              alignItems: "center",
              p: [6],
              width: "100%",
            }}
          >
            <GatsbyImage
              image={items[selectedItem].image.gatsbyImageData}
              alt=""
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Accordion
